import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = () => {
  var settings = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: false,
          draggable: true,
          speed: 300,
        },
      },
    ],
  };

  return (
    <SimpleReactLightbox>
      <div className="portfolio_inner my_carousel gallery_zoom">
        <ul>
          <SRLWrapper>
            <Slider {...settings}>
              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0438.JPG">
                      <img src="img/portfolio/IMG_0438.JPG" alt="" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0439.JPG">
                      <img src="img/portfolio/IMG_0439.JPG" alt="" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0440.JPG">
                      <img src="img/portfolio/IMG_0440.JPG" alt="" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0441.JPG">
                      <img src="img/portfolio/IMG_0441.JPG" alt="University Life" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="400"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0442.JPG">
                      <img src="img/portfolio/IMG_0442.JPG" alt="" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0443.JPG">
                      <img src="img/portfolio/IMG_0443.JPG" alt="University Life" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="list_inner">
                  <div className="image">
                    <a href="img/portfolio/IMG_0444.JPG">
                      <img src="img/portfolio/IMG_0444.JPG" alt="University Life" />
                    </a>
                  </div>
                </div>
              </li>
              {/* End li */}

            </Slider>
          </SRLWrapper>
        </ul>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
