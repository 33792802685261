import React from "react";
import {
  SiKrita,
  SiGimp,
  SiAdobephotoshop
} from "react-icons/si";

const AcumenShare = [
];
const Acumen = () => {
  return (
    <ul>
      {AcumenShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank">
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Acumen;
