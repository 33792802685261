import React from "react";

const Address = () => {
  return (
    <ul className="wrapper">
      <li data-aos="fade-right" data-aos-duration="1200" >
        <div className="list_inner">
          <h3>Email:</h3>
          <ul className="in">
            <li>
              <a href="mailto:info@lashauna.art">info@lashauna.art</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  );
};

export default Address;
