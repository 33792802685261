import React from "react";
import Acumen from "../Acumen";

const About = () => {
  return (
    //    ABOUT
    <div className="edina_tm_about" id="about">
      <div className="container">
        <div className="about_title">
          <h3>About Me</h3>
        </div>
        <div className="content">
          <div
            className="leftpart"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <div className="info">
              <h3>
                Greetings 👋🏾
              </h3>
              <p>
                I’m an artist who enjoys to use traditional and digital mediums. I love to sketch and I am on a journey to master color & rendering. I will be adding my artwork as it develops. Thanks for viewing!
              </p>
            </div>
            <div className="wrapper">
              <div className="dodo-progress"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="300"
          >
                  <Acumen />
              </div>
            </div>
          </div>
				
          {/* End leftpart */}

          <div className="rightpart">
            <div className="image">
              <img src="img/thumbs/26-35.jpg" alt="thumb" />
              <div
                className="main"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "img/about/IMG_0518.JPG"
                  })`,
                }}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="200"
              ></div>
              <div
                className="experience"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="info">
                  <h3>Graduate</h3>
                  <span>Fashion Institute of Technology</span>
                </div>
              </div>
            </div>
          </div>
          {/* End righttpart */}
        </div>
      </div>
    </div>
    // /ABOUT
  );
};

export default About;
